
#useractivitylogId th{
  text-align: center;
}
#useractivitylogId td:nth-child(1),
#useractivitylogId td:nth-child(2),
#useractivitylogId td:nth-child(3),
#useractivitylogId td:nth-child(4){
  text-align: center;
}
#useractivitylogId td:nth-child(6){
  text-align: left;
}
#useractivitylogId td:nth-child(2),
#useractivitylogId td:nth-child(3){
  width: 100px;
}
#useractivitylogId td:nth-child(4),
#useractivitylogId td:nth-child(5){
  width: 150px;
}
#useractivitylogId td:nth-child(1){
  width:10px;
}


