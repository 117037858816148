#productSummaryTableId th{
    text-align: center;
}

#productSummaryTableId td:nth-child(1),
#productSummaryTableId td:nth-child(3),
#productSummaryTableId td:nth-child(4),
#productSummaryTableId td:nth-child(5),
#productSummaryTableId td:nth-child(6),
#productSummaryTableId td:nth-child(7),
#productSummaryTableId td:nth-child(8),
#productSummaryTableId td:nth-child(9){
    text-align: center;
}
#productSummaryTableId td:nth-child(2){
    width: 214px;
}
#productSummaryTableId > tbody > tr:last-child { font-weight: bolder; }