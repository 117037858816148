.casesTable {
    width: 100%;
}

.casesTable .casestd {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9e9e9;
}

.casesTable .casetd {
    padding: 0.75rem;
    vertical-align: top;
}

.casesTable .casetd2 {
    text-align: end;
}