//
// authentication.scss
//

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

.authentication-bg {
  background: url("../../../images/Banner-New.jpg");
  background-size: cover;
  background-position: center;
  //position: absolute;
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  //background-color: $auth-bg;
  .auth-logo {
    .logo {
      margin: 0px auto;
      width: 100%;
      height: auto;
    }
  }
}

// signin card title

.signin-other-title {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: $border-color;
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 9;
    background-color: $card-bg;
    padding: 2px 16px;
  }
}

// auth carousel

.auth-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: $card-bg;
        }
        &.active {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}
.account-pages-container {
  // background: url("../../../images/bg.jpg");
  // background-size: cover;
  // background-position: center;
  // position: absolute;
  // background-size: cover;
  // height: 100%;
  // width: 100%;
  // top: 0;
  .account-pages {
    a {
      color: #343a40;
      @media only screen and (max-width: 600px) {
        display: block;
      }
    }
    .right-button {
      text-align: right;

      .btn {
        background-color: #67a8e4;
        border-color: #67a8e4;
      }
    }
  }
}

.cardHover {
transition: 0.3s;
cursor: pointer;
}
.cardHover:hover{
  box-shadow: 3px 3px 9px 0px #919191;
}
.button_class{
  border: none;
    color: #e5e1e1;
    cursor: pointer;
    background: #f56363;
    border-radius: 50px;
}
.profileCard{
  text-align: center;
  border: 1px solid #e9e9e9;
}
.button_class2{
  border: none;
  cursor: pointer;
  width: 56px;
  float: right;
  position: relative;
  background-color: #c3c3c3;
  color: white;
  border-radius: 50px;
}
.accordion-button:not(.collapsed) {
  color: #212529;
}