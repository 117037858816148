.action{
  display: 'flex';
  justify-content: center;
}
.action > * {
  margin-left:0.5em ;
  font-size: '1em';
  cursor: pointer;
}
#privilegeTableId td:nth-child(5){
  text-align: center;
}
#privilegeTableId th{
  text-align: center;
}