.main_chatbody{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px 20px;
    display: flex;
}

.main_chatlist{
    border-right: 1px solid #ebe7fb;
    padding: 0 40px 0 0px;
    width: 350px
}

.chatlist_items{
    margin-top: 30px;
    overflow: auto;
    max-height: 55vh;
}

.chatlist_item {
   
    border-bottom: 1px solid #ebe7fb;
    padding-bottom: 10px;
 
    cursor: pointer;
    padding: 10px 10px 10px 20px;
    display: flex;
}

.chatlist_item.active{
   
    
    background-color: #f2f7ff;
   
 cursor: pointer;
    
}


.avatar{

    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
    

}



.isOnline{

    position: absolute;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #ddd;
    border-radius: 50%;
    border: 2px solid #fff;

}

.isOnline.active{
    background-color: tomato;;
}


.chatlist_item .userMeta p {
    margin: 0;
    padding: 0;
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

.chatlist_item .userMeta span {
    margin: 0;
    padding: 0;
    color: #ceccd3;
    font-weight: 400;
    font-size: 12px;
    display: block;
}

.main_chatcontent {
    position: relative;
    flex-grow: 1;
    padding: 20px 40px;
    max-width: 100%;
    border-right: 1px solid #ebe7fb;
    width: calc( 100% - 350%);
}


.content_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebe7fb;

}
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.current-chatting-user {
    display: flex;
    align-items: center;
}

.content_body {
    max-height:55vh;
    overflow: auto;
}

.chat_item {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    animation-direction: normal;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.2s;
    animation-iteration-count: 1;
    animation-duration: .2s;
    
    animation-delay: 0.3s;
}

.chat_item_content {
    background-color: #4462ff;
    color: #fff;
    padding: 15px;
    border-radius: 10px 10px 0 10px;
    max-width: 50%;
    min-width: 215px;
}

.chat_item_content .chat_meta,.chat_item_content .chat_metame {
    justify-content: space-between;
    display: flex;
    margin-top: 10px;
}

.chat_item_content .chat_meta span{
    font-size: 13px;
    color: #c5c7ce;
    -webkit-user-select: none;
    user-select: none;
}

.chat_item_content .chat_metame span{
    font-size: 13px;
    color: #0a29c494;
    -webkit-user-select: none;
    user-select: none;
}



.chat_item .avatar {
    margin-right: 0px;
    margin-left: 20px;
    background: #fff;
    padding: 1px;
}



.chat_item.other {
    flex-direction: row-reverse;
    -webkit-transform-origin: left;
    transform-origin: left;
}

.chat_item.other .chat_item_content {
    background-color: #EEE;
    color: #000;
    border-radius: 10px 10px 10px 0;
    max-width: 50%;
}

.chat_item.other .avatar {
    margin-right: 20px;
    margin-left: 0px;
}


.content_footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
    
}

.sendNewMessage {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
}

.addFiles{
    cursor: pointer;
}

.sendNewMessage input {
    flex-grow: 1;
    padding: 0 15px;
    background-color: transparent;
    border: none;
    outline: none;
}
#sendMsgBtn {
    background-color: #3b5bfe;
    color: #fff;
}

// .active{
//     background-color: gray;
// }

.chatlist_items::-webkit-scrollbar {
    width: 10px;
  }

.chatlist_items::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.441); 
    border-radius: 10px;
  } 

  .chatlist_items::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.258); 
    border-radius: 10px;
  }



  .content_body::-webkit-scrollbar {
    width: 10px;
  }

.content_body::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.441); 
    border-radius: 10px;
  } 

  .content_body::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.258); 
    border-radius: 10px;
  }
  




  .search_wrap {
    background-color: #e6e5ea;
    border-radius: 5px;
}

.search_wrap input {
    width: 80%;
    padding: 15px 0 15px 15px;
}

.search_wrap input {
    background-color: initial;
    border: none;
    outline: none;
}

.search-btn {
    height: 46px;
    width: 20%;
    cursor: pointer;
    font-size: 20px;
}

.search-btn{
    background-color: initial;
    border: none;
    outline: none;
}

.inboxitem .active-all {
    background-color: #1a71b5;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin: 10px 5px;
    font-size: 15px;
    cursor: pointer;
    border: 1px solid;

    text-align: center;
    color: #fff;
}

.whatsapp {
    background-color: hsla(0,0%,100%,.75);
    background-color: 1a71b5;
    color: #128c7e;
}

.inboxitem .facebook,.inboxitem .whatsapp {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin: 10px 5px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid;
}

.inboxitem .facebook {
    background-color: #ebf2f7;
    color: #3b5998;
}

.inboxitem .instagram,.inboxitem .twitter {
    background-color: #ebf2f7;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    margin: 10px 5px;
    font-size: 15px;
    text-align: center;
    background-color: 1a71b5;
    cursor: pointer;
    border: 1px solid;
}
.inboxitem .instagram {
    color: #3f729b;
}
.inboxitem .twitter {
    color: #00acee;
}

.avatar_img img{

    width: 100%;
}



// 

.chat_body1{ max-height: 50vh;
overflow: auto;
}


.sendNewMessage button {
    width: 36px;
    height: 36px;
    background-color: #ecefff;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: #4665ff;
    padding: 0;
    border-radius: 5px;
    line-height: 36px;
    transition: all .3s cubic-bezier(.88,.19,.37,1.11);
}