#sourceSummaryTableId th{
    text-align: center;
}

#sourceSummaryTableId td:nth-child(1){
    text-align: center;
    width: 10px;
}
#sourceSummaryTableId td:nth-child(2),
#sourceSummaryTableId td:nth-child(3){
    width: 300px;
}
#sourceSummaryTableId td:nth-child(4),
#sourceSummaryTableId td:nth-child(5),
#sourceSummaryTableId td:nth-child(6){
    width: 132px;
}
#sourceSummaryTableId td:nth-child(2),
#sourceSummaryTableId td:nth-child(3),
#sourceSummaryTableId td:nth-child(6){
    text-align: left;
}