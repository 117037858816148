.newpost {
    background-color: white;
    padding: 10px;
    position: relative;
    margin-bottom: 15px;
    border-radius: 2px;
}

.newpostcol{
    border-bottom: 1px solid #eeee;
}

.newpost .firstdiv{
    white-space: nowrap;
    overflow: auto;
    display: flex;
    gap: 15px;
}

.newpostprofile{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    
    position: relative;
}

.newpostprofile img {
    width: 100%;
}

// .profile_subimg{

//     position: absolute;
//     /* margin-top: 26px; */
//     width: 17px;
//     height: 17px;
//     bottom: -3px;
//     right: -11px;

// }

.profile_img1{
    display: flex;
    background-color: white;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    bottom: -1px;
    right: -1px;
   

}

.profile_img1 img{
    width: 100%;

}


.addnew{
    color: #1A71B5;
    border: 1px solid #1A71B5;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
    background-color: rgba(26, 113, 181, 0.1);
}
.addnew span{
    text-align: center;
}

.dropdownaddnew {
    z-index: 9;
    background-color: white;
    box-shadow: 0px 0px 20px 5px #6666661f;
    padding: 10px;
    gap: 10px;
    border-radius: 5px;
    width: 300px;
    position: absolute;
    height: 320px;
    margin-top: 10px;
}

.dropdownaddnew ul{
    list-style: none;
    padding: 10px 10px;
    text-decoration: none;
}


.channelheader{
    border-bottom: 1px solid #66666617;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.selectchannel{
    display: flex;
    padding: 15px 10px;
    border-bottom: 1px solid #66666617;
    gap: 10px ;
}


.selectchannel .channelinput{ 
    display: flex;
}

.selectchanneldiv h6{
    margin-bottom: 0px;     
}


.channellogo{
    height: 22px;
}

.channelclicklist {
    overflow: hidden;
    height: 210px;
    overflow-y: auto;
}

.channelclicklist::-webkit-scrollbar {
    width: 10px;
  }

.channelclicklist::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.441); 
    border-radius: 10px;
  } 

  .channelclicklist::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.258); 
    border-radius: 10px;
  }
  

.channellistsocial{
    display: flex;
    gap: 10px;
    padding: 10px 10px 0px 10px;
    
    
    
}

.channellistsocial .channelfont1{
    font-weight: 500;
    font-size: 14px;

} 

.channellistsocial .channelinput{
    padding-top: 9px;
}

.channellistsocial .channelimg{
    padding-top: 5px;
}



.newpostcol1{
    border-left: 1px solid #eeee;
    
}

.newpostcol1 .active label{
    color: #1A71B5;
    font-weight: 500;
}

.newpostcol1 p{
    margin-bottom: 0px;
    color: gray;
     
}

.postingfoto div img{
    border-radius: 5px;
    height: 130px;
    overflow: hidden;
    width: 130px;
}
.postingfoto div{
    position: relative;
}

.postingfoto div button{
    top: 4px;
    padding: 3px;
    border-radius: 15px;
    font-size: 5px;
    position: absolute;
    right: 4px;
    background-color: #a4a4a4;
}

.addnewpost{

    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addnew1{
    cursor: pointer;
    
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
    background-color: rgba(26, 113, 181, 0.1);
}

.addnew1 span{
    color:#1A71B5 ;

}

.socialnewpost{
    border-bottom: 1px solid #eeee;
}



.newpostbutton button{
    background-color: #1775bc29;
    color: #1A71B5;
    border-radius: 5px;
    padding: 9px 13px;;
    border: none;
}
.newpostbutton .newbutton1{
    background-color: #1A71B5 !important;
    color: white !important;
    padding: 9px 20px;
}

.newpostcol1 label{
      font-size: 14px;
    font-weight: 500;
    padding-left: 8px;
    color: rgb(88, 88, 88);
}

.publishnow{
    padding: 10px 0px;
    border-bottom: 1px solid #eee;
   
}
.publishnow label{
    cursor: pointer;
}



.publishnowdate input{
    width: 116px;
    height: 30px;
    padding: 5px !important;
    color: gray;

}
.publishnowtime input{
    width: 30px;
    height: 30px;
    padding: 5px !important;
  
}

.btn-check{
    background-color: #1A71B5;
    color: white;
}


.ampmbutton button{
    background-color: white;
    color: #1A71B5;
    border-radius: 5px;
    width: 34px;
    height: 30px;
    border: none;
}

.ampmbutton .active1{
    background-color: #1A71B5;
    color: white;
}

.newposttable th{
    background-color:rgba(26, 113, 181, 0.05) !important;

}
.newposttable td{
    color: gray !important;
}


@media screen and (max-width:400px) {

    .newpostbutton{

        margin-top: 10px
    }
    .newpostbutton button {
        
        padding: 6px 11px;
        
    }
    .newpostbutton .newbutton1 {
        
        padding: 6px 15px;
    }



    
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
input[type="number"] {
    -moz-appearance: textfield;
    }


.icon3{
    border-right: 1px solid #cccccc54;
}

.form-check-input[type=checkbox] {
    border-radius: 0.25em;
    font-size: 14px;
}

#file-input {
    display: none;
  }

  #file-input-label {
    font-size: 1.3em;
  
    
    border-radius: 4%;
  }

  .revnextpage{
    padding: 0px 5px;
    border-left: 1px solid #eee;
    height: 35px;
  }
  .revpagelist{
    padding: 0px 4px 0px 16px;
  }
  
  .revnextpage .pagination a {
    color: #1A71B5;
    float: left;
    padding: 8px 11px;
    text-decoration: none;
  }
  
  
  .revpagecontent{
    border-left: 1px solid #eee;
    height: 30px;
  
    gap: 8px;   
    display: flex;
    padding: 0px 2px 0 12px;
    padding-top: 5px;
  }
  
  .revpagecontent div{
  
    height: 25px;
    text-align: center;
    width: 25px;
    border-radius: 5px; 
    border: 1px solid #eee;
  
  }
