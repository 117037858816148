#responseSummaryTableId th{
    text-align: center;
}

#responseSummaryTableId > tbody > tr:last-child { font-weight: bolder; }

#responseSummaryTableId td:nth-child(1),
#responseSummaryTableId td:nth-child(3),
#responseSummaryTableId td:nth-child(4),
#responseSummaryTableId td:nth-child(5),
#responseSummaryTableId td:nth-child(6),
#responseSummaryTableId td:nth-child(7),
#responseSummaryTableId td:nth-child(8),
#responseSummaryTableId td:nth-child(9){
    text-align: center;
}
#responseSummaryTableId td:nth-child(2){
    width: 214px;
}