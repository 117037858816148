.reviews {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
}

ul.platforms {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    overflow: auto;
    margin-bottom: 0;
    margin-left: -5px;
    margin-right: -5px;
}

ul.platforms li {
    min-width: 120px;
    width: 100%;
    overflow: hidden;
    padding: 0 5px;
}

ul.platforms li div:hover {
    border-color: #1a71b5;
    color: #1a71b5;
}

ul.platforms li.active div {
    background-color: #1a71b5;
    color: #fff;

}

ul.platforms li.active .mainfont {

    color: #fff;
}

ul.platforms li.active .subikon {
    filter: brightness(0) invert(1);
}

ul.platforms li.active div:hover {
    background-color: #1a71b5;
    color: #fff;

}



ul.platforms li div {
    cursor: pointer;
    border-radius: 5px;
    display: block;
    text-align: center;
    color: #a2a2a2;
    text-decoration: none;
    border: 1px solid #ccc;
    padding: 6px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
}

ul.platforms li .fa {
    display: block;
    font-size: 20px;
    margin: 7px 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
}

ul.ikon {
    display: flex;
    padding: 0;

    padding-top: 11px;
}

ul.ikon li {
    min-width: auto;
    font-size: 12px;


}

ul.ikon li:nth-child(2) {
    border-left: 1px solid #eee;

}

ul.platforms li.active ul.ikon li:nth-child(2) {
    border-left: 1px solid #cccccc54;

}


ul.ikon li .subikon {

    height: 14px;
}

.mainfont {
    color: #2A2A2A;
    font-weight: 400;
    // font-size: 16px;
}


.filtercols {
    margin-top: 15px;
}

.filtercols .dropdown {
    float: left;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #ddd;
}

.filtercols .dropdown ul {
    padding: 0;
}

//   .filtercols .dropdown ul li a{
//     border-bottom: 1px solid #ddd;
//     color: #999;
//     padding: 6px 10px;
//   }
//   .filtercols .dropdown ul li a:hover{
//     background-color: rgba(26, 113, 181, .03);
//     color: #1a71b5;
//   }
//   .filtercols .dropdown ul li:last-child a{
//     border-bottom: none;
//   }


.filtercols .input-group input {
    box-shadow: none;
}

.filtercols nav {
    float: right;
}

.filtercols ul.pagination {
    margin: 0;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #ddd;
    border-radius: 0;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #1a71b5;
    border-color: #1a71b5;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
    background-color: #1a71b5;
    border-color: #1a71b5;
}

.btn-bordered {
    border-color: #1a71b5;
    color: #1a71b5;
    background-color: #fff;
}

.btn-bordered .fa {
    margin-right: 5px;
}


// 

.reviewbox {
    background-color: #fff;
    border-radius: 7px;
    padding: 10px;
    margin-bottom: 15px;
}

.reviewbox .profile {
    min-width: 50px;
    width: 50px;
    height: 50px;

    border-radius: 50%;
    text-align: center;
    background-color: #2DA771;
}

.reviewbox .profile span.fab {
    line-height: 50px;
    font-size: 20px;
    color: white;

}

// .reviewbox .profile i img .source {
//     position: absolute;
//     margin-top: 26px;
//     width: 20px;
//     height: 20px;
//     background-color: white;

//     border-radius: 50%;
//     line-height: 26px;
//     text-align: center;
//     color: white;
// }
.profile img {
    position: absolute;
    margin-top: 26px;
    width: 20px;
    height: 20px;
    background-color: white;

    border-radius: 50%;
    line-height: 26px;
    text-align: center;
    color: white;
}

.revhead {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.revname span {
    font-weight: 400;
    opacity: 57%;

}

.revtitle {
    font-weight: 500;
}

.revname {
    margin-left: 20px;
}

.revhead .date {
    width: 200px;
    text-align: right;
    color: #999;
    font-size: 12px;

}

.captions {
    display: flex;


}

.butn1 {
    font-size: smaller;
    background-color: white;
    // color: rgb(26, 113, 181);
    padding: 3px;
    width: 70px;
}

.date .dropdown .btn {
    padding: 1px;
    font-size: 13px;
    color: #999;
    border-color: #999;
}

.date span img {
    padding-right: 5px;
}

.revcontent {

    padding: 10px 0;
}

.revimage {
    display: flex;
    padding: 10px 0;

}

.revimages {
    padding-right: 10px;
}

.revfooter {
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.revtool {
    display: flex;
}

.revtool div {
    padding: 0 10px;
    cursor: pointer;

}

.revlist {

    margin-bottom: 20px;
}

.revfooter1 {
    border-bottom: none !important;

}

.revlist1 {
    margin-bottom: 0 !important;
}

.revreplycontent {
    display: flex;
    flex-flow: wrap;
    margin: 20px 0px;
    list-style: none;
    padding-left: 0;
    gap: 6px;

}

.revreplycontent li {
    margin-right: 10px;

}

.revreplycontent li button {
    cursor: pointer;
    border: 1px solid #1A71B5;
    border-radius: 1.3rem;
    background-color: #1A71B51A;
    padding: 8px;
    text-decoration: none;
    color: #1A71B5;
}

.replybutton {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.replybutton button {

    background-color: #1775bc29;
    border-radius: 5px;
    padding: 10px 23px;
    border: none;
}

.replybutton .replybutn1 {
    background-color: #1A71B5;
    color: white;
}

.replybutton .replybutn {
    background-color: #1775bc29;
    color: #1A71B5;


}


.reviewbar {

    display: flex;
    padding-top: 10px;
}

.reviewbar .input-group {

    height: 35px;
    border-left: 1px solid #eee;
    padding: 0px 15px;
    width: 450px !important;
}

.reviewbar .dropdown0 {
    padding-right: 15px;


}

.reviewbar .dropdown1:hover{
background-color: blue;
    border-color: blue;
   


}



.reviewbar ul.pagination {
    margin: 0;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #eee;
    border-radius: 0;
}

.revpagelist{
    padding: 0px 4px 0px 16px;
}

.revnextpage{
    padding: 0px 5px;
    border-left: 1px solid #eee;
    height: 35px;
}

.revnextpage .pagination li{
    cursor: pointer;
    color: #1A71B5;
    float: left;
    padding: 8px 11px;
    text-decoration: none;
  }


.revpagecontent{
    border-left: 1px solid #eee;
    height: 30px;
    gap: 8px;   
    display: flex;
    padding: 0px 2px 0 12px;
    padding-top: 5px;
    
}

.revpagecontent div{

    height: 25px;
    text-align: center;
    width: 25px;
    border-radius: 5px; 
    border: 1px solid #eee;

}

.revnextpage .pagination .nextpage2{
    text-align: center;
    color: white;
    width: 35px;
    height: 35px;
    background-color: #1A71B5;
    border-radius: 50px;
}


.revpagelist .dropdown{
    text-align: center;
}

.revpagelist .dropdown-toggle{
  
   
    height: 18px;
    background-color: white;
    color: rgb(26, 113, 181);
    padding: 0px;
    width: 43px;
    border-radius: 5px; 
    text-align: center;
    border: 1px solid #eee;
    
}



.pagination-link {
    background-color: white; /* Set the background color for inactive links */
    text-align: center;
    margin: 0 2px;
    text-decoration: none;
    color: white;
    border-radius: 50px;
    width: 35px;
    height: 35px;


}

.pagination-link.active {
    background-color:#1A71B5; /* Set the background color for active link */
    color: #fff !important;
}
