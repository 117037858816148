#modulesTableId th{
  text-align: center;
  padding: 10px;
}

#modulesTableId td:nth-child(1){
  width: 10px;
}
#modulesTableId td:nth-child(2){
  width: 200px;
}
#modulesTableId td:nth-child(3),
#modulesTableId td:nth-child(4),
#modulesTableId td:nth-child(5){
  width: 100px;
}
#modulesTableId td:nth-child(1),
#modulesTableId td:nth-child(4) {
  text-align: center;
}
#modulesTableId th:nth-child(4) {
  text-align: center;
}
