#productCatTableId th{
    text-align: center;
}
#productCatTableId  td:nth-child(1),
#productCatTableId  td:nth-child(2),
#productCatTableId  td:nth-child(3){
    text-align: center;
}
#productCatTableId  td:nth-child(2),
#productCatTableId  td:nth-child(3),
#productCatTableId  td:nth-child(4),
#productCatTableId  td:nth-child(5),
#productCatTableId  td:nth-child(6){
    width: 25%;
}
#productCatTableId td:nth-child(1){
    width:10px;
  }