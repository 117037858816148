

.monitors{
    background-color: white;
    padding:0px 10px;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    border-radius: 5px;
}

.monitors img{
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
}

.monithead{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 10px 10px;
//     overflow: hidden;
  background-color: white;
  position: sticky;
  top: 0;
  width: 100%;
    
    

}

.monithead div{
    display: flex;
    gap: 9px;
}

.monithead img{
    height: 24px;
    width: 24px;

}


.moniticons{
    padding-top: 8px;

}

.moniticons a{
    cursor: pointer;
    color: rgb(80, 79, 79);
}


.monitbody{
    padding: 10px 0px 5px 0;
    display: flex;


}
.monitbody .profile{
    display: flex;
    justify-content: center;
    width: 43px;
    height: 43px;
    border: 1px solid green;
    text-align: center;

    border-radius: 50%;
    align-items: center;
    background-color: #2DA771;
}

.monitbody .profile span.fab {
    line-height: 50px;
    font-size: 20px;
    color: white;
    padding: 0px;

}

.monitbody ul{

text-decoration: none;
    padding-left: 20px;
}

.monitbody ul li{
    list-style: none;
}

.monitbody ul .monitname{
    display: flex;
}
 
.monitdownbar{

    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;

}
.monitfooter{
    padding: 10px 0px;
    border-bottom: 1px solid #eee;

}

.monitfooter a{
   cursor: pointer;
   color: gray;

   padding-right: 15px;
}





  .monitors::-webkit-scrollbar {
    width: 10px;
  }

.monitors::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.441); 
    border-radius: 10px;
  } 

  .monitors::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.258); 
    border-radius: 10px;
  } 