
.mainval {
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
}

.title1 {
    font-size: 1rem;
    font-weight: 700;
    color: #282828;
}

.dateText {
    text-align: end;
    margin-top: -21px;
    border: 0;
    background: transparent;
}